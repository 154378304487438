export const matrix = {
	chrome: {
		    48: 0b00010010101000110011111,
		    49: 0b00010011111001111111111,
		    50: 0b00010111111001111111111,
		    51: 0b00010111111001111111111,
		    52: 0b00011111111001111111111,
		    53: 0b00011111111001111111111,
		    54: 0b00011111111001111111111,
		    55: 0b01011111111001111111111,
		    56: 0b01011111111001111111111,
		    57: 0b01011111111001111111111,
		    58: 0b01111111111001111111111,
		    59: 0b01111111111001111111111,
		    60: 0b11111111111001111111111,
		    61: 0b11111111111001111111111,
		    62: 0b11111111111001111111111,
		    63: 0b11111111111001111111111,
		    64: 0b11111111111001111111111,
		    65: 0b11111111111001111111111,
		    66: 0b11111111111001111111111,
		    67: 0b11111111111001111111111,
		    68: 0b11111111111001111111111,
		    69: 0b11111111111001111111111,
		    70: 0b11111111111001111111111,
		    71: 0b11111111111001111111111
	},
	firefox: {
		    43: 0b00010011101000110111011,
		    44: 0b00010011101000110111011,
		    45: 0b00010011101000110111111,
		    46: 0b00010111101000110111111,
		    47: 0b00010111101000111111111,
		    48: 0b00010111101000111111111,
		    49: 0b00010111101000111111111,
		    50: 0b00010111101000111111111,
		    51: 0b00010111101001111111111,
		    52: 0b01111111111001111111111,
		    53: 0b01111111111001111111111,
		    54: 0b01111111111001111111111,
		    55: 0b11111111111001111111111,
		    56: 0b11111111111001111111111,
		    57: 0b11111111111001111111111,
		    58: 0b11111111111001111111111,
		    59: 0b11111111111001111111111,
		    60: 0b11111111111001111111111,
		    61: 0b11111111111001111111111,
		    62: 0b11111111111001111111111,
		    63: 0b11111111111001111111111,
		    64: 0b11111111111001111111111
	},
	safari: {
		     8: 0b00010000000000000001001,
		     9: 0b00010010001000011011101,
		    10: 0b00110111111001111111111,
		'10.1': 0b01111111111001111111111,
		    11: 0b01111111111001111111111,
		'11.1': 0b11111111111001111111111,
		    12: 0b11111111111001111111111
	},
	ie: {
		     8: 0b00000000000000000000000,
		     9: 0b00010000000000000000001,
		    10: 0b00010000000000000000001,
		    11: 0b00010000000000000000001 // no let/const in for loops
	},
	edge: {
		    12: 0b00010010101000010011011,
		    13: 0b00010111101000110011111,
		    14: 0b00111111101001111111111,
		    15: 0b01111111101001111111111,
		    16: 0b01111111101001111111111,
		    17: 0b01111111101001111111111,
		    18: 0b01111111101001111111111,
		    19: 0b01111111101001111111111
	},
	node: {
		'0.10': 0b00010000000000000000001,
		'0.12': 0b00010000000000010000001,
		     4: 0b00010010001000110011111,
		     5: 0b00010010001000110011111,
		     6: 0b00010111111001111111111,
		     8: 0b01111111111001111111111,
		 '8.3': 0b11111111111001111111111,
		 '8.7': 0b11111111111001111111111,
		'8.10': 0b11111111111001111111111
	}
};

export const features = [
	'getterSetter',
	'arrow',
	'classes',
	'computedProperty',
	'conciseMethodProperty',
	'defaultParameter',
	'destructuring',
	'forOf',
	'generator',
	'letConst',
	'moduleExport',
	'moduleImport',
	'numericLiteral',
	'parameterDestructuring',
	'spreadRest',
	'stickyRegExp',
	'templateString',
	'unicodeRegExp',

	// ES2016
	'exponentiation',

	// additional transforms, not from
	// https://featuretests.io
	'reservedProperties',

	'trailingFunctionCommas',
	'asyncAwait',
	'objectRestSpread'
];
